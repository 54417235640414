import { data } from "./data";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import LOGO_IMG from "../../assets/images/logo.png";
import { RoutePattern } from "../../routes/RoutePattern";

const Container = styled.div`
  z-index: 50;
`;

export default function Navigation() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { currentRoute } = useSelector((state: RootState) => state.Routes);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      const scrollY = window.scrollY;
      if (scrollY >= 100) {
        setScrolled(true);
      } else if (scrollY < 100) {
        setScrolled(false);
      }
    };
  }, []);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    item.smooth && currentRoute === "/" ? (
      <button
        onClick={() => {
          setIsActive(false);

          document.getElementById(item.name)?.scrollIntoView({
            behavior: "smooth",
          });
        }}
        className="link"
      >
        {item.name}
      </button>
    ) : item.to ? (
      <Link
        to={item.to}
        onClick={() => {
          setIsActive(false);
        }}
        className="link"
      >
        {item.name}
      </Link>
    ) : (
      <a
        key={idx}
        href={item.to} // route
        onClick={() => setIsActive(false)}
        className="link"
      >
        {item.name}
      </a>
    )
  );

  return (
    <Container
      id="main-navbar"
      className={`${
        scrolled ? "fixed opacity-100 bg-primary shadow-sm" : "fixed bg-none"
      } font-secondary mx-auto flex flex-col justify-center items-center text-white px-5 top-0 left-0 transition-all duration-500 w-full py-[2vh] lg:px-[5vw]`}
    >
      <div className="w-full flex justify-center items-center">
        <div className="w-full">
          <button
            onClick={() => {
              setIsActive(false);

              document.getElementById(RoutePattern.HOME)?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            {/* <h2 className=" text-primary text-[3vh] lg:text-[5vh]">
            Bossmandlow
            </h2> */}
            <img
              className="w-[150px] md:w-[15vw] opacity-100"
              src={LOGO_IMG}
              alt="logo"
            />
          </button>
        </div>

        {/* desktop navigation */}
        <div className="uppercase justify-between items-center flex-row hidden md:flex ">
          {menuItems}
        </div>

        {/* tablet navigation */}
        <div
          className={`gap-5 uppercase justify-center items-center flex-col flex fixed top-0 bg-opacity-80 transition-all duration-700 w-full h-screen bg-black md:hidden
            ${isActive ? "right-0" : "-right-full"}
        `}
        >
          {menuItems}
        </div>
        {/* burger */}
        <div
          className="flex-col ml-auto z-50 cursor-pointer mr-5 flex md:hidden"
          aria-label="button"
          onClick={handleClick}
        >
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
              isActive ? "rotate-45" : "rotate-0"
            } transition-all`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm mt-1 ${
              isActive ? "hidden" : "block"
            }`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
              isActive ? "-rotate-45 absolute" : "rotate-0 mt-1"
            } transition-all`}
          />
        </div>
      </div>
    </Container>
  );
}
