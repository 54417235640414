import { RoutePattern } from "../../routes/RoutePattern";
import Showcase from "./Showcase";
import DESKTOP_IMG from "../../assets/images/bg.desktop.png";
import MOBILE_IMG from "../../assets/images/bg.mobile.png";
import StickyBG from "../../components/StickyBG/StickyBG";
import Videos from "./Videos";
import Streaming from "./Streaming";
import Bio from "./Bio";
import Tour from "./Tour";
import Merch from "./Merch";
import Press from "./Press";
import Subscribe from "./Subscribe";
import Gallery from "../../components/Gallery/Gallery";
import Navigation from "../../components/Navigation/Navigation";
import Music from "./Music";
import YoutubeSlider from "../../components/YoutubeSlider/YoutubeSlider";
import { YoutubeItemTypes } from "../../components/Arrows/types";

const data: YoutubeItemTypes[] = [
  {
    title: "",
    videoId: "",
    stream: "",
  },
];

const Home = () => {
  return (
    <div
      id={RoutePattern.HOME}
      className="relative flex flex-col h-full min-h-screen"
    >
      {/* <StickyBG desktopImg={DESKTOP_IMG} mobileImg={MOBILE_IMG} /> */}
      <Showcase />
      <Music />
      <Videos />
      <Tour />
      {/* <Videos /> */}
    </div>
  );
};

export default Home;
