import { RoutePattern } from "../../routes/RoutePattern";

interface RouteTypes {
  name: string;
  to?: string;
  smooth: boolean;
  isAnchor?: boolean;
}

export const data: RouteTypes[] = [
  {
    name: RoutePattern.HOME,
    smooth: true,
  },
  {
    name: RoutePattern.MUSIC,
    smooth: true,
  },
  {
    name: RoutePattern.VIDEOS,
    smooth: true,
  },
  {
    name: RoutePattern.TOUR,
    smooth: true,
  },
  // {
  //   name: RoutePattern.STREAMING,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.BIO,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.TOUR,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.MERCH,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.GALLERY,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.SUBSCRIBE,
  //   smooth: true,
  // },
];
