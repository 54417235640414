import styled from "styled-components";
import { ArrowTypes } from "./types";

export const Arrow = styled.button<ArrowTypes>`
  position: absolute;
  z-index: 2;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;
  :focus {
    outline: none;
  }

  top: 35%;
  right: ${(props) => props.right && props.right};
  left: ${(props) => props.left && props.left};
`;
