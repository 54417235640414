import {
  faAmazon,
  faApple,
  faFacebookF,
  faInstagram,
  faSnapchat,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faSpotify,
    link: "https://open.spotify.com/artist/23xFbA9rYgRX8mreqysWPc?si=iBnophdlRPWxc4T0wNUDJA&nd=1&dlsi=39ebb2dec4c24a49",
  },
  {
    icon: faApple,
    link: "https://music.apple.com/us/artist/bossman-dlow/1474308236?at=1001l36AA&ct=LFV_33d9518d76bb33d4b8e3121da417330a&itsct=catchall_p1&itscg=30440&ls=1",
  },
  {
    icon: faAmazon,
    link: "https://music.amazon.com/artists/B08NFGH7MF/bossman-dlow?tag=linkfiregen&ie=UTF8&linkCode=as2&ascsubtag=2da36f72e7bfe9bd981c26697f97f96f&ref=dmm_acq_soc_us_u_lfire_lp_x_2da36f72e7bfe9bd981c26697f97f96f",
  },
  {
    icon: faTwitter,
    link: "https://twitter.com/bossman_dlow",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/bossman_dlow/",
  },
  {
    icon: faFacebookF,
    link: "https://www.facebook.com/bossmandlow",
  },
  {
    icon: faYoutube,
    link: "https://www.youtube.com/@bossmandlow",
  },
  {
    icon: faTiktok,
    link: "https://www.tiktok.com/@bossmandlow",
  },
  {
    icon: faSoundcloud,
    link: "https://soundcloud.com/bossman-dlow",
  },
  {
    icon: faSnapchat,
    link: "https://www.snapchat.com/add/bossman.dlow?invite_id=jQzxdV74&locale=en_US&share_id=DosOeQRIT0mtai1GIAySzg&sid=87381d43651e4a1dbd75bb0ce88849be",
  },
];
