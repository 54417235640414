import { motion } from "framer-motion";
import styled from "styled-components";
import { ArrowTypes } from "./types";

export const MusicDetail = styled(motion.div)``;

export const MusicImage = styled(motion.a)`
  transition: all 0.3s ease;
  overflow: hidden;
  text-align: right;
  width: 30vw;

  /*:hover img {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }*/

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const Arrow = styled.button<ArrowTypes>`
  display: b100%rops) => props.left && "38%"};
  position: absolute;
  z-index: 2;
  top: 110%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;
  :focus {
    outline: none;
  }

  @media (min-width: 980px) {
    display: block;
    top: 50%;
    right: ${(props) => props.right && props.right};
    left: ${(props) => props.left && props.left};
  }
`;

export const SlideItem = styled.a`
  max-width: 350px;
  width: 100%;
  height: auto;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    max-width: 800px;
    /* max-width: 700px; */
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    max-width: 1000px;
  }
`;
